<template>
  <div>
    <vx-card title="Les intermédiaires" class="mb-4">
      <div slot="no-body">
        <div class="aab-vie-content">
          <div>
            <vs-prompt
              title="Exportation"
              class="export-options"
              @cancel="clearFields"
              @accept="exportToData"
              accept-text="Valider"
              cancel-text="Annuler"
              @close="clearFields"
              :active.sync="activePrompt"
            >
              <vs-input
                v-model="fileName"
                placeholder="Entrez le nom du fichier..."
                class="w-full"
              />
              <multiselect
                v-model="selectedFormat"
                :options="formats"
                class="my-4"
                placeholder="--Choisir--"
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              >
                <template v-slot:noOptions>
                  <span>{{ global.selectNotFoundData }}</span>
                </template>
              </multiselect>

              <div class="flex">
                <span class="mr-4">Largeur automatique de cellule:</span>
                <vs-switch v-model="cellAutoWidth"
                  >Largeur automatique de cellule</vs-switch
                >
              </div>
            </vs-prompt>
            <div
              class="
                flex flex-wrap-reverse
                items-center
                data-list-btn-container
              "
            >
              <div>
                <vs-button
                  v-if="checkUserRole('broker').create"
                  @click="popupAddUserActive = true"
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  >Ajouter</vs-button
                >
                <vx-tooltip
                  color="primary"
                  :title="role.notAllowTitle"
                  :text="role.notAllowText"
                  v-else
                >
                  <vs-button
                    :disabled="true"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    >Ajouter</vs-button
                  >
                </vx-tooltip>
              </div>
               <div>
                <div>
                  <vs-dropdown class="dd-actions cursor-pointer ml-4">
                    <div
                      class="
                        p-3
                        shadow-drop
                        rounded-lg
                        d-theme-dark-bg
                        cursor-pointer
                        flex
                        items-center
                        justify-center
                        text-lg
                        font-medium
                        w-32 w-full
                      "
                    >
                      <span class="mr-2">Afficher {{ limit }}</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item
                        :key="i"
                        v-for="(r, i) in rowsTable"
                        @click="limit = r"
                      >
                        <span class="flex items-center">
                          <span>{{ r }}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
              <div>
                <vs-dropdown class="dd-actions cursor-pointer ml-4">
                  <div
                    class="
                      p-3
                      shadow-drop
                      rounded-lg
                      d-theme-dark-bg
                      cursor-pointer
                      flex
                      items-center
                      justify-center
                      text-lg
                      font-medium
                      w-32 w-full
                    "
                  >
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="activePrompt = true">
                      <span class="flex items-center">
                        <feather-icon
                          icon="PrinterIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Exporter</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="checkUserRole('broker').delete"
                      :disabled="userSelected.length == 0"
                      @click="checkRemoveMultipleUser"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="Trash2Icon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Supprimer la sélection</span>
                      </span>
                    </vs-dropdown-item>
                    <vx-tooltip
                      color="primary"
                      :title="role.notAllowTitle"
                      :text="role.notAllowText"
                      v-else
                    >
                      <vs-dropdown-item :disabled="true">
                        <span class="flex items-center">
                          <feather-icon
                            icon="Trash2Icon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Supprimer la sélection</span>
                        </span>
                      </vs-dropdown-item>
                    </vx-tooltip>
                    <vs-dropdown-item
                      v-if="checkUserRole('broker').send"
                      :disabled="userSelected.length == 0"
                      @click="checkSentPublipostage"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="MailIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Publipostage</span>
                      </span>
                    </vs-dropdown-item>
                    <vx-tooltip
                      color="primary"
                      :title="role.notAllowTitle"
                      :text="role.notAllowText"
                      v-else
                    >
                      <vs-dropdown-item :disabled="true">
                        <span class="flex items-center">
                          <feather-icon
                            icon="MailIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Publipostage</span>
                        </span>
                      </vs-dropdown-item>
                    </vx-tooltip>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
             
            </div>
            <div class>
              <div class="w-auto" v-if="loading">
                <clip-loader :loading="loading" color="primary"></clip-loader>
              </div>
              <div v-else>
                <div v-if="allUsers.list.length !== 0">
                  <vs-table
                    multiple
                    @dblSelection="brokerSelectedDouble"
                    :data="allUsers.list"
                    v-model="userSelected"
                    search
                    stripe
                    pagination
                    :max-items="limit != 'Tout' ? limit : total"
                    noDataText="Aucun résultat trouvé"
                  >
                    <template slot="thead">
                      <vs-th>N°</vs-th>
                      <vs-th sort-key="type">Profil</vs-th>
                      <vs-th sort-key="identity">Identité</vs-th>
                      <vs-th sort-key="userCode">Code Apporteur</vs-th>
                      <vs-th sort-key="subscription">Projet(s)</vs-th>
                      <vs-th sort-key="police">Contrat(s)</vs-th>
                      <vs-th>Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>
                        <vs-td :data="data[indextr].type"
                          >{{ displayProfil(data[indextr].type) }}
                        </vs-td>
                        <vs-td :data="data[indextr].identity">{{
                          data[indextr].identity
                        }}</vs-td>

                        <vs-td :data="data[indextr].userCode">{{
                          data[indextr].userCode
                        }}</vs-td>
                        <vs-td :data="data[indextr].projectBroker">
                          <div v-if="data[indextr].projectBroker">
                            {{ data[indextr].projectBroker.all }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].projectBroker">
                          <div v-if="data[indextr].projectBroker">
                            {{ data[indextr].projectBroker.active }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <vx-tooltip
                              color="primary"
                              :title="lastLogin(data[indextr]).title"
                              :text="lastLogin(data[indextr]).text"
                            >
                              <div class="cursor-pointer">
                                <feather-icon
                                  icon="FlagIcon"
                                  class="text-primary"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>

                            <vs-dropdown class="dd-actions cursor-pointer ml-6">
                              <div class="cursor-pointer">
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                              <vs-dropdown-menu class="aab-vie-dropdown">
                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('broker').update"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkUpdateUser(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          class="text-primary"
                                          icon="Edit2Icon"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Modifier</span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="Edit2Icon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Modifier</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('broker').update"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="
                                        checkInitializeUser(data[indextr])
                                      "
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          class="text-primary"
                                          icon="RefreshCcwIcon"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Réinitialiser</span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="RefreshCcwIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Réinitialiser</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('broker').lock"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="ChangeStatuts(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          :icon="
                                            tooltipIconText({
                                              status: data[indextr].status,
                                            }).icon
                                          "
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">
                                          {{
                                            tooltipIconText({
                                              status: data[indextr].status,
                                            }).text
                                          }}
                                        </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            :icon="
                                              tooltipIconText({
                                                status: data[indextr].status,
                                              }).icon
                                            "
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">
                                            {{
                                              tooltipIconText({
                                                status: data[indextr].status,
                                              }).text
                                            }}
                                          </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>
                                <div>
                                  <vs-dropdown-item
                                    v-if="
                                      checkUserRole('broker').delete &&
                                      data[indextr].userCode !== 'aabvie'
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkRemoveUser(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="Trash2Icon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Supprimer </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="Trash2Icon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Supprimer </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('broker').send"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="sendMail(data[indextr].id)"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="SendIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Envoyer mail </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="SendIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Envoyer mail </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('broker').show"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="
                                        checkViewLogo(data[indextr].person.logo)
                                      "
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="DownloadIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3"
                                          >Télécharger le logo</span
                                        >
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="DownloadIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Télécharger le logo</span
                                          >
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('broker').show"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="copyLink(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="CopyIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3"
                                          >Lien production
                                        </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="CopyIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Lien production
                                          </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>
                              </vs-dropdown-menu>
                            </vs-dropdown>

                            <vx-tooltip
                              position="left"
                              color="primary"
                              :title="aboutElement(data[indextr]).title"
                              :text="aboutElement(data[indextr]).text"
                            >
                              <div class="cursor-pointer ml-6">
                                <feather-icon
                                  icon="InfoIcon"
                                  class="text-primary"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>

                            <div
                              class="ml-2"
                              v-show="woIsAction == data[indextr].id"
                            >
                              <clip-loader
                                :loading="
                                  loadingRemoveUser ||
                                  loadingUpdateUser ||
                                  loadingInitUser ||
                                  loadingAllowUser
                                "
                                color="primary"
                                size="25px"
                              ></clip-loader>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <vs-pagination
                    class="mt-6"
                    :total="allPages"
                    v-model="currentPage"
                  ></vs-pagination>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <vs-popup
            class="holamundo"
            title="Ajouter un intermédiaire"
            :active.sync="popupAddUserActive"
          >
            <div class="m-4">
              <div class="clearfix">
                <div>
                  <vs-row vs-w="12">
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Type*</span>
                        <multiselect
                          v-model="brokerType"
                          :options="brokerTypeList"
                          placeholder="--Choisir--"
                          label="label"
                          track-by="label"
                          v-validate="'required'"
                          name="brokerType"
                          required
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                          <template v-slot:noOptions>
                            <span>{{ global.selectNotFoundData }}</span>
                          </template>
                        </multiselect>

                        <div class="aab-vie-error-msg">
                          {{ errors.first("brokerType") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Profil*</span>
                        <multiselect
                          v-model="profil"
                          :options="profilList"
                          placeholder="--Choisir--"
                          label="label"
                          track-by="label"
                          v-validate="'required'"
                          name="Profil"
                          required
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                          <template v-slot:noOptions>
                            <span>{{ global.selectNotFoundData }}</span>
                          </template>
                        </multiselect>

                        <div class="aab-vie-error-msg">
                          {{ errors.first("Profil") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      v-if="specialProfil"
                    >
                      <div v-if="loadinIntermediary">
                        <clip-loader
                          :loading="loadinIntermediary"
                          color="primary"
                        ></clip-loader>
                      </div>
                      <div v-else class="w-full">
                        <span class="pl-2">Intermédiaires rattachés*</span>
                        <multiselect
                          v-model="form.intermediaries"
                          :options="intermediariesList"
                          placeholder="--Choisir--"
                          label="fullname"
                          track-by="fullname"
                          v-validate="'required'"
                          :multiple="true"
                          :close-on-select="false"
                          name="Intermédiaires rattachés"
                          required
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                          <template v-slot:noOptions>
                            <span>{{ global.selectNotFoundData }}</span>
                          </template>
                        </multiselect>

                        <div class="aab-vie-error-msg">
                          {{ errors.first("Intermédiaires rattachés") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Code Apporteur*"
                          placeholder="Code Apporteur"
                          v-model="form.userCode"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="code apporteur"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("code apporteur") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div v-if="brokerType.id == 'company'" class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Société*"
                          v-model="form.forSociety"
                          placeholder="Société"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="Société"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("Société") }}
                        </div>
                      </div>
                      <div v-else class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Société "
                          v-model="form.forSociety"
                          placeholder="Société"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre nom"
                          label="Nom*"
                          v-model="form.lastname"
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="nom"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("nom") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre prénom(s)"
                          label="Prénom(s)*"
                          v-model="form.firstname"
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="prénom"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("prénom") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre nom d'utilisateur"
                          label="Nom d'utilisateur*"
                          v-model="form.username"
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="nom d'utilisateur"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("nom d'utilisateur") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Adresse*"
                          placeholder="Adresse"
                          v-model="form.addres"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="Adresse"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("Adresse") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Téléphone*"
                          label="Téléphone* "
                          v-model="form.phone"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="Téléphone"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("Téléphone") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre email"
                          label="Email*"
                          v-model="form.email"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          type="email"
                          name="email"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("email") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="12"
                    >
                      <div class="w-full">
                        <div>
                          <div class="ml-2">Logo</div>
                          <div
                            class="uk-margin"
                            style="margin-top: 5px !important"
                          >
                            <div uk-form-custom class="aab-vie-upload-file">
                              <input
                                type="file"
                                v-on:change="checkUploadLogo"
                                name="Fichier"
                              />
                              <button
                                style="cursor: pointer"
                                class="uk-button uk-button-default"
                                type="button"
                                tabindex="-1"
                              >
                                Choisir un logo
                              </button>
                              <div class="aab-vie-error-msg">
                                {{ errors.first("Fichier") }}
                              </div>
                              <div
                                class="mt-6 mb-3"
                                style="display: flex; flex-direction: column"
                              >
                                <span v-if="displayFileName">
                                  <strong>{{ form.logo }}</strong>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="aab-vie-error-msg" v-show="formError">
                            Erreur lors du chargement du logo
                          </div>
                        </div>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      class="w-full sm:w-auto"
                      :disabled="!validateFormAddUser || loadingAddUser"
                      @click="fetchApplyAddUser"
                      >Valider</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      type="border"
                      @click="popupAddUserActive = false"
                      >Annuler</vs-button
                    >
                  </div>
                  <div class="ml-2">
                    <clip-loader
                      :loading="loadingAddUser"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
                <div class="mt-6">
                  <p class="text-danger">* Champ obligatoire</p>
                </div>
              </div>
            </div>
          </vs-popup>

          <vs-popup
            class="holamundo"
            title="Modifier un intermédiaire"
            :active.sync="popupUpdateUserActive"
          >
            <div class="m-4">
              <div class="clearfix">
                <div>
                  <vs-row vs-w="12">
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Type*</span>
                        <multiselect
                          v-model="brokerUpdatedType"
                          :options="brokerTypeList"
                          placeholder="--Choisir--"
                          label="label"
                          track-by="label"
                          v-validate="'required'"
                          name="brokerType"
                          required
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                          <template v-slot:noOptions>
                            <span>{{ global.selectNotFoundData }}</span>
                          </template>
                        </multiselect>

                        <div class="aab-vie-error-msg">
                          {{ errors.first("brokerType") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Profil*</span>

                        <multiselect
                          v-model="profilUpdated"
                          :options="profilList"
                          placeholder="--Choisir--"
                          label="label"
                          track-by="label"
                          v-validate="'required'"
                          name="Profil"
                          required
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                          ><template v-slot:noOptions>
                            <span>{{ global.selectNotFoundData }}</span>
                          </template>
                        </multiselect>

                        <div class="aab-vie-error-msg">
                          {{ errors.first("Profil") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      v-if="specialUpdatedProfil"
                    >
                      <div v-if="loadinIntermediary">
                        <clip-loader
                          :loading="loadinIntermediary"
                          color="primary"
                        ></clip-loader>
                      </div>
                      <div v-else class="w-full">
                        <span class="pl-2">Intermédiaires rattachés*</span>
                        <multiselect
                          v-model="formUpdated.intermediaries"
                          :options="intermediariesListUpdated"
                          placeholder="--Choisir--"
                          label="fullname"
                          track-by="fullname"
                          :multiple="true"
                          :close-on-select="false"
                          v-validate="'required'"
                          name="Intermédiaires rattachés"
                          required
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                          <template v-slot:noOptions>
                            <span>{{ global.selectNotFoundData }}</span>
                          </template>
                        </multiselect>

                        <div class="aab-vie-error-msg">
                          {{ errors.first("Intermédiaires rattachés") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          :disabled="formUpdated.userCode == 'aabvie'"
                          class="inputx w-full mb-2 mt-2"
                          label="Code Apporteur*"
                          v-model="formUpdated.userCode"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="code apporteur"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("code apporteur") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div
                        v-if="brokerUpdatedType.id == 'company'"
                        class="w-full"
                      >
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Société*"
                          v-model="formUpdated.forSociety"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="Société"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("Société") }}
                        </div>
                      </div>
                      <div v-else class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Société "
                          v-model="formUpdated.forSociety"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre nom"
                          label="Nom*"
                          v-model="formUpdated.lastname"
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="nom"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("nom") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre prénom(s)"
                          label="Prénom(s)*"
                          v-model="formUpdated.firstname"
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="prénom"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("prénom") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre nom d'utilisateur"
                          label="Nom d'utilisateur*"
                          v-model="formUpdated.username"
                          disabled
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="nom d'utilisateur"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("nom d'utilisateur") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Adresse*"
                          v-model="formUpdated.addres"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="Adresse"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("Adresse") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Téléphone*"
                          label="Téléphone "
                          v-model="formUpdated.phone"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="Téléphone"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("Téléphone") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre email"
                          label="Email*"
                          v-model="formUpdated.email"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          type="email"
                          name="email"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("email") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="6"
                    >
                      <div class="w-full">
                        <div>
                          <div class="ml-2">Logo</div>
                          <div
                            class="uk-margin"
                            style="margin-top: 5px !important"
                          >
                            <div uk-form-custom class="aab-vie-upload-file">
                              <input
                                type="file"
                                v-on:change="checkUploadLogoUpdated"
                                name="Fichier"
                              />
                              <button
                                style="cursor: pointer"
                                class="uk-button uk-button-default"
                                type="button"
                                tabindex="-1"
                              >
                                Choisir un logo
                              </button>
                              <div class="aab-vie-error-msg">
                                {{ errors.first("Fichier") }}
                              </div>
                              <div
                                class="mt-6 mb-3"
                                style="display: flex; flex-direction: column"
                              >
                                <span v-if="displayFileName">
                                  <strong>{{ formUpdated.logo }}</strong>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="aab-vie-error-msg"
                            v-show="formUpdatedError"
                          >
                            Erreur lors du chargement du logo
                          </div>
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      v-if="formUpdated.person"
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="6"
                    >
                      <div v-if="formUpdated.person.logo" class="w-full">
                        <img
                          style="height: 60px !important"
                          :src="`${$config.serverURL}/downloads/${formUpdated.person.logo}`"
                          alt=""
                        />
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      class="w-full sm:w-auto"
                      :disabled="!validateFormUpdateUser || loadingUpdateUser"
                      @click="fetchApplyUpdateUser"
                      >Valider</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      type="border"
                      @click="popupUpdateUserActive = false"
                      >Annuler</vs-button
                    >
                  </div>
                  <div class="ml-2">
                    <clip-loader
                      :loading="loadingUpdateUser"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
                <div class="mt-6">
                  <p class="text-danger">* Champ obligatoire</p>
                </div>
              </div>
            </div>
          </vs-popup>

          <vs-popup
            class="holamundo"
            title="Liste des fichiers"
            :active.sync="popupListFiles"
          >
            <div v-if="filesList.length != 0" class="m-4">
              <p
                v-for="item in filesList"
                :key="item.filename"
                style="cursor: pointer"
                @click="checkViewFile(item)"
              >
                <b
                  ><span> {{ item.name }} </span></b
                >
                <vs-divider />
              </p>
            </div>
            <div v-else>
              <div class="flex items-center justify-center mt-8">
                <strong>Aucun enregistrement</strong>
              </div>
            </div>
          </vs-popup>

          <vs-popup
            class="holamundo"
            title="Lien de production"
            :active.sync="popupCopyLink"
          >
            <div>
              <div class="mt-10 mb-10 aab-vie-share-link">
                <vs-row
                  vs-align="center"
                  vs-type="flex"
                  vs-justify="center"
                  vs-w="12"
                  class="mt-4"
                >
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                    <div class="aab-vie-input-container mr-4">
                      <strong>{{ link }}</strong>
                    </div>
                    <div>
                      <vs-button
                        v-clipboard:copy="link"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        >Copier</vs-button
                      >
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </div>
          </vs-popup>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      link: "https://app.aps-benin.com/pr/5WYt5n_",
      popupListFiles: false,
      filesList: [],
      brokerType: "",
      brokerUpdatedType: "",

      brokerTypeList: [
        { id: "company", label: "Entreprise" },
        { id: "particular", label: "Particulier" },
      ],
      profilList: [
        { id: "broker_inspector", label: "Inspecteur" },
        { id: "broker_leader", label: "Chef d'équipe" },
        { id: "broker_merchant", label: "Commercial" },
        { id: "broker_general_agent", label: "Agent général" },
        { id: "broker", label: "Courtier" },
      ],
      intermediariesList: [],
      intermediariesListUpdated: [],
      intermediariesTmp: [],
      profil: "",
      profilUpdated: "",
      specialProfil: false,
      specialUpdatedProfil: false,
      role: {},
      global: {},
      fileData: {},
      fileDataUpdated: {},
      oldLogo: "",
      displayFileName: false,
      formData: {},
      formUpdatedError: false,
      formError: false,
            fileName: "",

      form: {
        email: "",
        firstname: "",
        lastname: "",
        username: "",
        type: "",
        intermediaries: [],
        personality: "",
        forSociety: "",
        logo: "",
      },
      usernameInit: "",
      allowStatus: "",
      formUpdated: {
        email: "",
        firstname: "",
        lastname: "",
        type: "",
        intermediaries: [],
        personality: "",
        forSociety: "",
        logo: "",
      },
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      loading: false,
      loadinIntermediary: false,
      loadingRemoveUser: false,
      loadingUpdateUser: false,
      loadingInitUser: false,
      loadingAddUser: false,
      loadingAllowUser: false,
      error: false,
      errorMsg: "",
      crrID: "",
      woIsAction: "",

      type: [
        "broker_inspector",
        "broker_leader",
        "broker_general_agent",
        "broker",
        "broker_merchant",
      ],
      //export section
      logo: "",
      formats: ["xlsx", "csv", "txt"], //formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["N°", "Nom", "Prénom", "Nom d'utilisateur", "Email"],
      headerVal: ["index", "lastname", "firstname", "username", "email"],
      activePrompt: false,
      //end export section
      popupAddUserActive: false,
      popupCopyLink: false,
      popupUpdateUserActive: false,
      userSelected: [],
      crrIDs: [],
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
  },
  mounted() {
    if (
      this.user.type != "super_administrator_sys" &&
      !this.user.person.userAcces.chk_intermediary
    ) {
      if (!this.checkUserRole("broker").all)
        this.$router.push({ name: "Home" });
    }
    this.limit = this.rowsTable[1];
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("users", ["allUsers"]),
    ...mapGetters("general", ["rowsTable"]),
    validateFormAddUser() {
      if (this.specialProfil) {
        if (this.brokerType.id == "company") {
          return (
            !this.errors.any() &&
            this.form.email != "" &&
            this.form.firstname != "" &&
            this.form.lastname != "" &&
            this.form.username != "" &&
            this.form.forSociety != "" &&
            this.form.intermediaries.length != 0 &&
            this.form.type != ""
          );
        } else {
          return (
            !this.errors.any() &&
            this.form.email != "" &&
            this.form.firstname != "" &&
            this.form.lastname != "" &&
            this.form.username != "" &&
            this.form.intermediaries.length != 0 &&
            this.form.type != ""
          );
        }
      } else {
        if (this.brokerType.id == "company") {
          return (
            !this.errors.any() &&
            this.form.email != "" &&
            this.form.firstname != "" &&
            this.form.lastname != "" &&
            this.form.username != "" &&
            this.form.forSociety != "" &&
            this.form.type != ""
          );
        } else {
          return (
            !this.errors.any() &&
            this.form.email != "" &&
            this.form.firstname != "" &&
            this.form.lastname != "" &&
            this.form.username != "" &&
            this.form.type != ""
          );
        }
      }
    },
    validateFormUpdateUser() {
      if (this.specialUpdatedProfil) {
        if (this.brokerUpdatedType.id == "company") {
          return (
            !this.errors.any() &&
            this.formUpdated.email != "" &&
            this.formUpdated.firstname != "" &&
            this.formUpdated.lastname != "" &&
            this.formUpdated.forSociety != "" &&
            this.formUpdated.intermediaries.length != [] &&
            this.formUpdated.type != ""
          );
        } else {
          return (
            !this.errors.any() &&
            this.formUpdated.email != "" &&
            this.formUpdated.firstname != "" &&
            this.formUpdated.lastname != "" &&
            this.formUpdated.intermediaries.length != [] &&
            this.formUpdated.type != ""
          );
        }
      } else {
        if (this.brokerUpdatedType.id == "company") {
          return (
            !this.errors.any() &&
            this.formUpdated.email != "" &&
            this.formUpdated.firstname != "" &&
            this.formUpdated.lastname != "" &&
            this.formUpdated.forSociety != "" &&
            this.formUpdated.type != ""
          );
        } else {
          return (
            !this.errors.any() &&
            this.formUpdated.email != "" &&
            this.formUpdated.firstname != "" &&
            this.formUpdated.lastname != "" &&
            this.formUpdated.type != ""
          );
        }
      }
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("general", ["applyUploadFile"]),
    ...mapActions("users", [
      "applyGetUserSys",
      "applyGetAllUsersSys",
      "applyGetIntermediariesSys",
      "applyAddUserSys",
      "applyPutUserSys",
      "applyRemoveUserSys",
      "applyAllowUserSys",
      "applyInitializeUserSys",
    ]),
    tooltipIconText(data) {
      // "Activativer / Désactiver"
      if (Object.keys(data) == "status") {
        return data.status === "inactive"
          ? { text: "Activer", icon: "LockIcon" }
          : { text: "Désactiver", icon: "UnlockIcon" };
      } else if (Object.keys(data) == "mask") {
        return data.isVisible
          ? { text: "Masquer", icon: "EyeOffIcon" }
          : { text: "Démasquer", icon: "EyeIcon" };
      }
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    async checkUploadLogo(evt) {
      this.formData = new FormData();
      this.formData.append("uri", evt.target.files[0]);
      this.form.logo = evt.target.files[0].name;
      this.displayFileName = true;
    },
    async checkUploadLogoUpdated(evt) {
      this.formData = new FormData();
      this.formData.append("uri", evt.target.files[0]);
      this.formUpdated.logo = evt.target.files[0].name;
      this.displayFileName = true;
    },

    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    sendMail(item) {
      console.log(item);
      setTimeout(() => {
        this.$router.push({
          name: "MailReceived",
          params: { userId: item, crrModuleName: "Brokers" },
        });
      }, 100);
    },
    copyLink(item) {
      this.link = `https://tarification.aabvie.net/?code=${item.userCode}`;
      this.popupCopyLink = true;
    },

    onCopy() {
      this.$vs.notify({
        title: "Succès",
        text: "Lien copier avec succès",
        color: "success",
        iconPack: "feather",
        position: "bottom-right",
        icon: "icon-check-circle",
      });
      this.popupCopyLink = false;
    },

    onError() {
      this.$vs.notify({
        title: "Echec",
        text: "Erreur lors de la copie du lien",
        color: "danger",
        iconPack: "feather",
        position: "bottom-right",
        icon: "icon-alert-circle",
      });
    },

    getFilesList(item) {
      this.filesList = item.filesname ? item.filesname : [];
      item.person.logo
        ? this.filesList.unshift({ filename: item.person.logo, name: "Logo" })
        : false;
      this.popupListFiles = true;
    },
    checkViewFile(item) {
      console.log("item", item);
      window.open(this.showFileData(item.filename), "_blank");
    },
    checkViewLogo(item) {
      console.log("item", item);
      window.open(this.showFileData(item), "_blank");
    },
    showFileData(data) {
      if (data == "") {
        return "";
      } else {
        return `${this.$config.serverURL}/downloads/${data}`;
      }
    },
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allUsers.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            type: this.profilList.find((type) => type.id == el.type).label,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email,
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES INTERMEDIAIRES"
        );
      } else {
        let tableToPrint = [];
        this.allUsers.list.forEach((el) => {
          let jsonTmp = {
            profil: this.profilList.find((type) => type.id == el.type)
              ? this.profilList.find((type) => type.id == el.type).label
              : "",
            type: this.brokerTypeList.find((type) => type.id == el.personality)
              ? this.brokerTypeList.find((type) => type.id == el.personality)
                  .label
              : "",
            userCode: el.userCode,
            lastname: el.person.lastname,
            firstname: el.person.firstname,
            forSociety: el.person.forSociety,
            addres: el.person.addres,
            phone: el.person.phone,
            username: el.username,
            email: el.email,
            logo: this.showFileData(el.person.logo),
            status: this.byStatusUserText(el.status),
            project: el.projectBroker.all,
            contrat: el.projectBroker.active,
            infos: this.aboutElement(el).text,
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "profil",
          "type",
          "firstname",
          "lastname",
          "username",
          "userCode",
          "email",
          "forSociety",
          "phone",
          "addres",
          "logo",
          "status",
          "project",
          "contrat",
          "infos",
        ];

        let headerTitleTmp = [
          "Profil",
          "Type",
          "Prénom(s)",
          "Nom",
          "Nom d'utilisateur",
          "Code apporteur",
          "Email",
          "Société",
          "Téléphone",
          "Adresse",
          "Logo",
          "Statut",
          "Projet",
          "Contrat",
          "Informations",
        ];

         mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    byStatusUserText(status) {
      // "Activer / Désactiver"
      return status === "inactive" ? "Désactiver" : "Activer";
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    async getIntermediarySys(data) {
      this.loadinIntermediary = true;

      try {
        let intermediary = await this.applyGetIntermediariesSys(data);
        this.form.intermediaries = [];
        this.formUpdated.intermediaries = [];
        this.intermediariesList = [];
        (this.intermediariesListUpdated = []),
          intermediary.list.forEach((el) => {
            this.intermediariesList.push({
              id: el.id,
              fullname: el.person.firstname + " " + el.person.lastname,
            });

            if (this.formUpdated.id != el.id) {
              this.intermediariesListUpdated.push({
                id: el.id,
                fullname: el.person.firstname + " " + el.person.lastname,
              });
            }
          });
        console.log(this.formUpdated);

        if (!this.intermediariesTmp.length == 0) {
          this.formUpdated.intermediaries = [...this.intermediariesTmp];
          this.intermediariesTmp = [];
        }
        this.loadinIntermediary = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loadinIntermediary = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async fetchApplyGetAllUsers(data) {
      this.loading = true;

      try {
        await this.applyGetAllUsersSys(data);

        this.total = this.allUsers.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async fetchApplyAddUser() {
      if (!this.validateFormAddUser) return false;
      this.loadingAddUser = true;

      if (this.form.logo) {
        try {
          this.fileData = await this.applyUploadFile(this.formData);
          this.acceptAlert(mixin.methods.message("broker", "file").success);
        } catch (error) {
          console.log(error);
          this.formError = true;
        }
      }

      let data = {};
      let Ids = [];

      if (
        this.form.type.id == "broker_inspector" ||
        this.form.type.id == "broker_leader"
      ) {
        this.form.intermediaries.forEach((el) => {
          Ids.push(el.id);
        });

        data = {
          ...this.form,
          logo: this.fileData.data ? this.fileData.data[0].uploadedName : "",
          type: this.form.type.id,
          intermediaries: Ids,
        };
      } else {
        data = {
          ...this.form,
          logo: this.fileData.data ? this.fileData.data[0].uploadedName : "",
          type: this.form.type.id,
          intermediaries: Ids,
        };
      }

      try {
        await this.applyAddUserSys(data);
        this.loadingAddUser = false;
        this.acceptAlert(mixin.methods.message("broker", "add").success);

        this.form = {
          email: "",
          firstname: "",
          lastname: "",
          username: "",
          type: "",
          intermediaries: [],
          personality: "",
          forSociety: "",
        };
        this.formData = {};
        this.fileData = {};
        this.displayFileName = true;

        this.popupAddUserActive = false;

        this.formError = false;
      } catch (err) {
        this.loadingAddUser = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddUserActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.acceptAlert(
                    mixin.methods.message("broker", "notFound").error
                  );
                  break;
                case "userNameExistError":
                  this.acceptAlert(
                    mixin.methods.message("broker", "nameExist").error
                  );
                  break;
                case "sendMailError":
                  this.acceptAlert(
                    mixin.methods.message("broker", "mailError").error
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("broker", "add").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    displayProfil(item) {
      return this.profilList.find((profil) => profil.id == item).label;
    },
    checkUpdateUser(item) {
      this.profilUpdated = this.profilList.find((el) => el.id == item.type);

      this.crrID = item.id;
      let itermediariesList = [];
      item.intermediaries.forEach((el) => {
        itermediariesList.push({
          id: el.id,
          fullname: el.person.firstname + " " + el.person.lastname,
        });
      });

      this.formUpdated = {
        ...item.person,
        ...item,
        intermediaries: itermediariesList,
      };

      this.brokerUpdatedType = this.brokerTypeList.find(
        (el) => el.id == item.personality
      );

      this.intermediariesTmp = itermediariesList;

      this.oldLogo = item.logo;
      this.formUpdated.logo = "";
      this.popupUpdateUserActive = true;
    },
    async fetchApplyUpdateUser() {
      if (!this.validateFormUpdateUser) return false;
      this.loadingUpdateUser = true;

      if (this.formUpdated.logo) {
        try {
          this.fileData = await this.applyUploadFile(this.formData);
          this.acceptAlert(mixin.methods.message("broker", "file").success);
        } catch (error) {
          this.formUpdatedError = true;
        }
      }

      let data = {};
      let Ids = [];
      console.log(this.formUpdated);
      this.formUpdated.intermediaries.forEach((el) => {
        Ids.push(el.id);
      });
      data = {
        userId: this.crrID,
        ...this.formUpdated,
        logo: this.fileData.data
          ? this.fileData.data[0].uploadedName
          : this.oldLogo,
        type: this.formUpdated.type.id,
        intermediaries: Ids,
      };

      console.log(data);

      try {
        await this.applyPutUserSys(data);

        this.acceptAlert(mixin.methods.message("broker", "update").success);

        this.formUpdated = {
          email: "",
          firstname: "",
          lastname: "",
          type: "",
          intermediaries: [],
          personality: "",
          forSociety: "",
        };
        this.formData = {};
        this.fileData = {};
        this.displayFileName = true;

        this.loadingUpdateUser = false;
        this.popupUpdateUserActive = false;
      } catch (err) {
        this.loadingUpdateUser = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupUpdateUserActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.acceptAlert(
                    mixin.methods.message("broker", "notFound").error
                  );
                  break;
                case "userNameExistError":
                  this.acceptAlert(
                    mixin.methods.message("broker", "nameExist").error
                  );
                  break;
                case "sendMailError":
                  this.acceptAlert(
                    mixin.methods.message("broker", "mailError").error
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("broker", "update").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkSentPublipostage() {
      let emailList = [];
      this.userSelected.forEach((user) => {
        emailList.push(user.email);
      });

      this.$router.push({
        name: "Mailing",
        query: { name: "broker", data: emailList },
      });
    },
    checkRemoveMultipleUser() {
      this.userSelected.forEach((element) => {
        this.crrIDs.push(element.id);
      });
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer les éléments sélectionnés ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchRemoveMultipleUsers,
      });
    },
    async fetchRemoveMultipleUsers() {
      let data = {
        ids: this.crrIDs,
      };

      this.loading = true;
      try {
        await this.applyRemoveUserSys(data);
        this.loading = false;
        this.acceptAlert(
          mixin.methods.message("broker", "multipleDeletion").success
        );

        this.crrIDs = [];
        this.userSelected = [];
      } catch (err) {
        this.loading = false;
        this.crrIDs = [];
        this.userSelected = [];

        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "brokerCenter":
                  this.acceptAlert(
                    mixin.methods.message("broker", "delete").error
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("broker", "multipleDeletion").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkRemoveUser(user) {
      this.crrID = user.id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ${user.person.lastname} ${user.person.firstname} ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveUser,
      });
    },
    async fetchApplyRemoveUser() {
      this.woIsAction = this.crrID;
      let data = {
        ids: [this.crrID],
      };
      this.loadingRemoveUser = true;
      try {
        await this.applyRemoveUserSys(data);
        this.loadingRemoveUser = false;
        this.acceptAlert(mixin.methods.message("broker", "delete").success);

        this.woIsAction = "";
        this.userSelected = [];
      } catch (err) {
        this.loadingRemoveUser = false;
        this.woIsAction = "";
        this.userSelected = [];
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Utilisateur n'existe pas"
                  );
                  break;
                case "brokerCenter":
                  this.acceptAlert(
                    mixin.methods.message("broker", "delete").error
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("broker", "delete").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkInitializeUser(item) {
      this.crrID = item.id;
      this.usernameInit = item.username;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment réinitialiser le mot de passe de ${item.person.lastname} ${item.person.firstname} ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyInitializeUser,
      });
    },
    async fetchApplyInitializeUser() {
      this.woIsAction = this.crrID;
      let data = {
        username: this.usernameInit,
      };

      this.loadingInitUser = true;
      try {
        await this.applyInitializeUserSys(data);
        this.loadingInitUser = false;
        this.acceptAlert(
          mixin.methods.message("broker", "reinitialise").success
        );

        this.woIsAction = "";
      } catch (err) {
        this.loadingInitUser = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Mot de passe",
                    "Utilisateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("broker", "reinitialise").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    ChangeStatuts(item) {
      this.crrID = item.id;
      let text = {};

      if (item.status === "inactive") {
        this.allowStatus = "active";
        text = `Voulez-vous vraiment activer ${item.person.lastname} ${item.person.firstname} ?`;
      } else {
        this.allowStatus = "inactive";
        text = `Voulez-vous vraiment désactiver ${item.person.lastname} ${item.person.firstname} ?`;
      }
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyAllowUser,
      });
    },
    async fetchApplyAllowUser() {
      this.woIsAction = this.crrID;
      let data = {
        userId: this.crrID,
        status: this.allowStatus,
      };
      let text = {};
      if (this.allowStatus === "inactive") {
        text = mixin.methods.message("broker", "desactive").success;
      } else {
        text = mixin.methods.message("broker", "active").success;
      }
      this.loadingAllowUser = true;
      try {
        await this.applyAllowUserSys(data);
        this.loadingAllowUser = false;
        this.acceptAlert(text);
        this.woIsAction = "";
      } catch (err) {
        this.loadingAllowUser = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Utilisateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("broker", "update").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    brokerSelectedDouble(item) {
      if (this.checkUserRole("broker").show) {
        this.sendMail(item.id);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    },
  },
  watch: {
    brokerType() {
      this.form.personality = this.brokerType.id;
    },

    brokerUpdatedType() {
      this.formUpdated.personality = this.brokerUpdatedType.id;
    },

    profil() {
      this.form.type = this.profil;
      console.log("profil", this.profil);
      switch (this.profil.id) {
        case "broker_inspector":
          this.specialProfil = true;
          this.getIntermediarySys({
            limit: "Tout",
            skip: 0,
            type: ["broker_leader"],
          });
          break;
        case "broker_leader":
          this.specialProfil = true;
          this.getIntermediarySys({
            limit: "Tout",
            skip: 0,
            type: ["broker_merchant", "broker_general_agent", "broker"],
          });
          break;
        default:
          this.specialProfil = false;
          this.form.intermediaries = [];
          break;
      }
    },

    profilUpdated() {
      this.formUpdated.type = this.profilUpdated;

      switch (this.profilUpdated.id) {
        case "broker_inspector":
          this.specialUpdatedProfil = true;
          this.getIntermediarySys({
            limit: "Tout",
            skip: 0,
            type: ["broker_leader"],
          });
          break;
        case "broker_leader":
          this.specialUpdatedProfil = true;
          this.getIntermediarySys({
            limit: "Tout",
            skip: 0,
            type: ["broker_merchant", "broker_general_agent", "broker"],
          });
          break;
        default:
          this.specialUpdatedProfil = false;
          this.formUpdated.intermediaries = [];
          break;
      }
    },
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllUsers({
        limit: this.limit,
        skip: this.skip,
        type: this.type,
      });
    },
    limit() {
      this.skip = 0;

      this.fetchApplyGetAllUsers({
        limit: this.limit,
        skip: this.skip,
        type: this.type,
      });
    },
  },
  components: {
    ClipLoader,
    Multiselect,
  },
};
</script>

<style lang="scss">
.aab-vie-icon-clickable {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.aab-vie-input-container {
  border: 2px solid #a0a0a0;
  text-align: center;
  padding: 10px;
  padding-bottom: 10px;
}
</style>
